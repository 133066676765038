.edgebutton {
    width: 20px;
    height: 20px;
    background: #eee;
    border: 1px solid #fff;
    cursor: pointer;
    border-radius: 50%;
    font-size: 12px;
    line-height: 1;
    justify-content: center;
    display: flex;
    align-self: center;
    align-items: center;
}

.edgebutton:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
}

.edgebutton-foreignobject body {
    background: transparent;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
}

.react-flow__edge-path {
    stroke-width: 3px;
}

.react-flow__edge.animated .react-flow__edge-path {
    stroke-dasharray: 5;
    animation: flowEdge 0.5s linear infinite;
}

@keyframes flowEdge {
    from {
        stroke-dashoffset: 10;
    }
    to {
        stroke-dashoffset: 0;
    }
}

.react-flow__edge.selected .react-flow__edge-path {
    stroke: #1c1111;
}
