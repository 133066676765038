/* Variáveis globais e tema light */
.react-flow {
    --node-bg: #f8f8f8;
    --node-border: #e0e0e0;
    --node-text: #333;
    --edge-color: #b1b1b7;
    --handle-border: #e0e0e0;
    --handle-bg: transparent;
    --shadow-color: rgba(0, 0, 0, 0.1);
    --selected-border: #F57DBD;
    --xy-node-border-radius-default: 6px;
}

/* Tema Dark */
.react-flow.dark { 
    --node-bg: #2d2d2d;
    --node-border: #404040;
    --node-text: #e0e0e0;
    --edge-color: #666;
    --handle-border: #404040;
    --handle-bg: transparent;
    --shadow-color: rgba(255, 255, 255, 0.05);
    --selected-border: #F57DBD;
    --xy-node-border-radius-default: 6px;
}

/* Estilização dos Nodes */
.react-flow__node {
    background-color: var(--node-bg);
    border: 2px solid var(--node-border);
    color: var(--node-text);
    border-radius: 6px;
    padding: 10px;
    font-size: 12px;
    transition: all 0.2s ease;
    box-shadow: 
        0px 3.54px 4.55px 0px var(--shadow-color),
        0px 0.51px 1.01px 0px var(--shadow-color);
}

/* Hover state */
.react-flow__node:hover {
    border-color: var(--selected-border);
}

/* Selected state */
.react-flow__node.selected {
    border-color: var(--selected-border);
    box-shadow: 0px 0px 0px 4px rgba(25, 118, 210, 0.2);
}

/* Handles (pontos de conexão) */
.react-flow__handle {
    width: 10px;
    height: 10px;
    /*right: -5px;*/
    /*background: var(--handle-bg);*/
    border: 2px solid var(--handle-border);
    border-radius: 6px;
    top: 50% !important;
}

/* Remove setinhas dos handles */
.react-flow__handle-right::after,
.react-flow__handle-left::after {
    display: none;
}

/* Edges (conexões) */
.react-flow__edge {
    pointer-events: all;
    cursor: pointer;
}

.react-flow__edge-path {
    stroke: var(--edge-color);
    stroke-width: 2;
    transition: all 0.2s ease;
    pointer-events: all;
}

.react-flow__edge:hover .react-flow__edge-path {
    stroke: var(--selected-border);
    stroke-width: 2.5;
}

/* Animação da linha */
.react-flow__edge.animated .react-flow__edge-path {
    stroke-dasharray: 2;
    animation: dashdraw 1s linear infinite;
}

/* Área de clique maior para edges */
.react-flow__edge-interaction {
    stroke-width: 10;
    opacity: 0;
    pointer-events: all;
}

@keyframes dashdraw {
    from {
        stroke-dashoffset: 10;
    }
}



/* Estilos para o tema escuro */
.react-flow.dark .react-flow__controls {
    background-color: #1E1E1E;
    border: 1px solid #333;
}

.react-flow.dark .react-flow__controls button {
    background-color: #1E1E1E;
    border-bottom: 1px solid #333;
    color: #fff;
}

.react-flow.dark .react-flow__controls button:hover {
    background-color: #333;
}

.react-flow.dark .react-flow__minimap {
    background-color: #1E1E1E;
    border: 1px solid #333;
}

/* Estilos para o tema claro */
.react-flow.light .react-flow__controls {
    background-color: #ffffff;
    border: 1px solid #eee;
}

.react-flow.light .react-flow__controls button {
    background-color: #ffffff;
    border-bottom: 1px solid #eee;
    color: #333;
}

.react-flow.light .react-flow__controls button:hover {
    background-color: #f8f8f8;
}

.react-flow.light .react-flow__minimap {
    background-color: #ffffff;
    border: 1px solid #eee;
}
